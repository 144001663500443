<template>
  <div class="dashboard">

    <el-row :gutter="24" class="panel-group">
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col"
            >
        <div class="card">
          <div class="title">
            <span>新增用户数<el-divider direction="vertical"></el-divider>活跃用户数</span>
            <!-- <span class="more" >一周内新增</span> -->
          </div>
          <div class="value">{{indexData.new_user_count}} <el-divider direction="vertical"></el-divider> {{indexData.active_user_count}}</div>
          <footer> 一周内新增、活跃用户数
            <!-- <router-link >一周内新增</router-link> -->
          </footer>
        </div>
      </el-col> 

      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col"
            >
        <div class="card">
          <div class="title">
            <span>当日新增<el-divider direction="vertical"></el-divider>活跃</span>
            <!-- <span class="more" >一周内新增</span> -->
          </div>
          <div class="value">{{indexData.new_day_user_count}} <el-divider direction="vertical"></el-divider> {{indexData.active_day_user_count}}</div>
          <footer> 用户数
            <!-- <router-link >一周内新增</router-link> -->
          </footer>
        </div>
      </el-col>
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col"
            >
        <div class="card">
          <div class="title">
            <span>当月新增<el-divider direction="vertical"></el-divider> 活跃</span>
            <!-- <span class="more" >一周内新增</span> -->
          </div>
          <div class="value">{{indexData.new_month_user_count}} <el-divider direction="vertical"></el-divider> {{indexData.active_month_user_count}}</div>
          <footer> 用户数
            <!-- <router-link >一周内新增</router-link> -->
          </footer>
        </div>
      </el-col>
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col"
            >
        <div class="card">
          <div class="title">
            <span>当年新增<el-divider direction="vertical"></el-divider> 活跃</span>
            <!-- <span class="more" >一周内新增</span> -->
          </div>
          <div class="value">{{indexData.new_year_user_count}} <el-divider direction="vertical"></el-divider> {{indexData.active_year_user_count}}</div>
          <footer> 用户数
            <!-- <router-link >一周内新增</router-link> -->
          </footer>
        </div>
      </el-col>


      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col"
            >
        <div class="card">
          <div class="title">
            <span>当日课程学习</span>
            <!-- <span class="more" >一周内新增</span> -->
          </div>
          <div class="value">{{indexData.course_day_count}}</div>
          <footer> 人次
            <!-- <router-link >一周内新增</router-link> -->
          </footer>
        </div>
      </el-col>


      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col"
            >
        <div class="card">
          <div class="title">
            <span>当月课程学习</span>
          </div>
          <div class="value">{{indexData.course_month_count}}</div>
          <footer> 人次
            <!-- <router-link >一周内新增</router-link> -->
          </footer>
        </div>
      </el-col>
      
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col"
            >
        <div class="card">
          <div class="title">
            <span>当年课程学习</span>
            <!-- <span class="more" >一周内新增</span> -->
          </div>
          <div class="value">{{indexData.course_year_count}}</div>
          <footer> 人次
            <!-- <router-link >一周内新增</router-link> -->
          </footer>
        </div>
      </el-col>
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col"
            >
        <div class="card">
          <div class="title">
            <span>总会员数</span>
            <!-- <span class="more" >一周内新增</span> -->
          </div>
          <div class="value">{{indexData.vip_count}}</div>
          <footer>  &nbsp;用户数
            <!-- <router-link >一周内新增</router-link> -->
          </footer>
        </div>
      </el-col>
    </el-row>


    <el-row :gutter="24" class="panel-group">
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col"
            >
        <div class="card">
          <div class="title">
            <span>当日订单数<el-divider direction="vertical"></el-divider>销售额</span>
            <!-- <span class="more" >一周内新增</span> -->
          </div>
          <div class="value">{{indexData.order_day_count}} <el-divider direction="vertical"></el-divider> {{indexData.order_day_payed}}</div>
      
        </div>
      </el-col> 

      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col"
            >
        <div class="card">
          <div class="title">
            <span>当月订单数<el-divider direction="vertical"></el-divider>销售额</span>
            <!-- <span class="more" >一周内新增</span> -->
          </div> 
          <div class="value">{{indexData.order_month_count}} <el-divider direction="vertical"></el-divider> {{indexData.order_month_payed}}</div>          
            <!-- <router-link >一周内新增</router-link> -->
             
        </div>
      </el-col> 

      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col"
            >
        <div class="card">
          <div class="title">
            <span>当年订单数<el-divider direction="vertical"></el-divider>销售额</span>
            <!-- <span class="more" >一周内新增</span> -->
          </div>
          <div class="value">{{indexData.order_year_count}} <el-divider direction="vertical"></el-divider> {{indexData.order_year_payed}}</div>         
          
        </div>
      </el-col> 
 
    </el-row>


    <el-row :gutter="24">
      <el-col :xs="{span: 24}" :sm="{span: 24}" :md="{span: 24}" :lg="{span: 12}" :xl="{span: 12}">
        <div class="card large">
          <div class="title"> 待处理订单 <span class="more" @click="load">刷新</span>
          </div>
          <el-table class="content table"
                    v-loading="loading"
                    :data="orders"
                    @row-click="row => $router.push('/shop/order-info?id=' + row.id)"
                    element-loading-text="获取数据中 ..."
                    fit highlight-current-row>
            <el-table-column align="center" label="ID" width="70" prop="id"/>
            <el-table-column label="用户" width="70">
              <template slot-scope="scope">
                <router-link :to="'/user-info?id=' + scope.row.user.id">{{ 
                  scope.row.user.realname || scope.row.user.nickname
                  }}
                  </router-link>
              </template>
            </el-table-column>
            <el-table-column label="付款金额">
              <template slot-scope="scope">
                <div>￥{{ scope.row.fee_paid }}</div>
              </template>
            </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <div>{{ scope.row.status }}</div>
                <div>{{ (scope.row.datetime_paid || '').slice(0, 10) }}</div>
              </template>
            </el-table-column>
          </el-table>
          <footer>
            <router-link to="/shop/order-list">查看所有</router-link>
          </footer>
        </div>


      </el-col>
      <el-col :xs="{span: 24}" :sm="{span: 24}" :md="{span: 24}" :lg="{span: 12}" :xl="{span: 12}"
              style="padding-right:8px;margin-bottom:30px;">
        <div class="card large">
          <div class="title">
            <el-radio-group v-model="userListType">
      <el-radio-button label="新增会员用户"></el-radio-button>
      <el-radio-button label="即将到期会员"></el-radio-button> 
    </el-radio-group>
       <span class="more" @click="load">刷新</span>
          </div>
          <el-table class="content table"
                    v-loading="loading"
                    :data="users"
                    @row-click="row => $router.push('/user-info?id=' + row.id)"
                    element-loading-text="获取数据中 ..."
                    fit highlight-current-row>
            <el-table-column align="center" label="UID" width="80" prop="id"/>
            <el-table-column label="用户" prop="realname"  > 
              <template slot-scope="scope">
                {{scope.row.realname || scope.row.nickname}}
                <br/>
                {{scope.row.tel }}
              </template> 
            </el-table-column>
            <el-table-column label="会员号" prop="is_vip" width="120">
              <template slot-scope="scope">
                {{scope.row.used_code }} 
              </template> 
            </el-table-column>
            <el-table-column label="会员开始/到期时间" prop="datetime_vip_expired" width="180"
                              >
              <template slot-scope="scope">
                {{scope.row.datetime_vip_start||'-'}} <br/>
                {{scope.row.datetime_vip_expired}} 
              </template> 
            </el-table-column>
          </el-table>
          <footer>
            <router-link to="/user-list">查看所有</router-link>
          </footer>
        </div>
      </el-col>
    </el-row>

    
  </div>
</template>

<script>


export default {
  name: 'Dashboard',

  components: {},

  data() {
    return {
      loading: false,
      currChartData: {},
      overview_data: [
        {name: '空1', value: 0, icon: 'el-icon-s-finance', color: '#40c9c6'},
        {name: '空2', value: 0, icon: 'el-icon-s-custom', color: '#36a3f7'},
        {name: '空3', value: 0, icon: 'el-icon-s-order', color: '#f4516c'},
        {name: '空4', value: 0, icon: 'el-icon-s-home', color: '#34bfa3'},
      ],
      indexData:{
        new_vip_list:[],
        end_vip_list:[],
      },
      orders: [], 
      orders_todo: [],
      userListType:'新增会员用户',
    }
  },
  mounted() {
    this.load()
  },
  computed:{
    users(){
      return this.userListType=='新增会员用户'?this.indexData.new_vip_list:this.indexData.end_vip_list;
    }
  },
  methods: {
    load() {
      let that = this;
      this.$api.request('/get_admin_dashboard_data').then(data => {
        // this.overview_data = data.overview
        this.orders = data.orders
        // this.users = data.users
      })

      this.$api.request('/adminapi/index/indexData', { 
        },{
          host: window.host2
        }).then(data => {
          that.indexData = data
        })

      }, 
    onSend() {

    }
  }
}
</script>

<style lang="scss" scoped>

.dashboard {
  padding: 32px;
}


.panel-group {
  .card-panel-col {
    margin-bottom: 24px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}


.card {
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, .65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
  transition: all .3s;

  padding: 20px 24px 10px;

  .value {
    color: rgba(0, 0, 0, .85);
    font-size: 30px;
    line-height: 38px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  footer {
    margin-top: 8px;
    padding-top: 9px;
    border-top: 1px solid #e8e8e8;
  }

  .more {
    float: right;
    font-size: 12px;
    color: rgba(0, 0, 0, .30);

  }

  &.large {
    padding: 10px;

    .title {
      margin: 7px 20px;
      font-size: 15px;
    }

    footer {
      margin-top: 8px;
      padding-top: 9px;
      border-top: none;
      text-align: right;
    }
  }
}
</style>
